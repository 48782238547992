<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถาบันการอาชีวศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                large
                right
                depressed
                color="primary"
                @click.native="veivocalAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="veivocals"
          :search="search"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon color="yellow" @click.stop="veivocalEdit(item.veiVocalID)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">            
            <v-icon
              color="red"
              
              @click.stop="veivocalDelete(item.veiVocalID)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addveivocaldialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addveivocaldialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลสถาบันการอาชีวศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addveivocalform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-text-field
                        label="รหัสสถาบันการอาชีวศึกษา"
                        v-model="addveivocal.veiVocalID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสถาบันการอาชีวศึกษา"
                        v-model="addveivocal.veiVocalName"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>                             
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addveivocaldialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addveivocalSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteveivocaldialog -->
      <v-layout>
        <v-dialog v-model="deleteveivocaldialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลสถาบันการอาชีวศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteveivocalform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{ editveivocal.veiVocalName }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteveivocaldialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteveivocalSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editveivocaldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editveivocaldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editveivocalform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>                   
                  <v-flex md12>
                      <v-text-field
                        label="รหัสสถาบันการอาชีวศึกษา"
                        v-model="editveivocal.veiVocalID"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="ชื่อสถาบันการอาชีวศึกษา"
                        v-model="editveivocal.veiVocalName"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>                        
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editveivocaldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editveivocalSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addveivocaldialog: false,
      editveivocaldialog: false,
      deleteveivocaldialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      veivocals: [],
      addveivocal: {},
      editveivocal: {},
      veivocals: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "รหัสสถาบันการอาชีวศึกษา", align: "left", value: "veiVocalID" },
        { text: "ชื่อสถาบันการอาชีวศึกษา", align: "left", value: "veiVocalName" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      veivocal: {},
      provinces: [],
      prefectures: [],
      veivocaltypes: [],
      collgegs: [],
      veivocalstatus: [],
      regions: [],
      region_ena: true,
      user_update: {},
      veivocalinfo_update: {}
    };
  },
  async mounted() {
    await this.veivocalQueryAll();
  },

  methods: {
    async veivocalQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("veivocal.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.veivocals = result.data;
    },

    async veivocalAdd() {
      this.addveivocal = {};
      this.addveivocaldialog = true;
    },
    async addveivocalSubmit() {
      if (this.$refs.addveivocalform.validate()) {
        this.addveivocal.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "veivocal.insert.php",
          this.addveivocal
        );       
        
        if (result.data.status == true) {        
           Swal.fire({
            icon: 'success',
            title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.veivocalQueryAll();
        } else {
          Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          this.veivocalQueryAll();
        }
        this.addveivocaldialog = false;
      }
    },

    async veivocalEdit(veiVocalID) {
      let result = await this.$http.post("veivocal.php", {
        ApiKey: this.ApiKey,
        veiVocalID: veiVocalID
      });
      this.editveivocal = result.data;
      this.editveivocaldialog = true;
    },
    async editveivocalSubmit() {
      if (this.$refs.editveivocalform.validate()) {
        this.editveivocal.ApiKey = this.ApiKey;   
        let result = await this.$http.post(
          "veivocal.update.php",
          this.editveivocal
        );       
        if (result.data.status == true) {        
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการแก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.veivocalQueryAll();
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.editveivocaldialog = false;
      }
    },
    async veivocalDelete(veiVocalID) {
      let result = await this.$http.post("veivocal.php", {
        ApiKey: this.ApiKey,
        veiVocalID: veiVocalID
      });
      this.editveivocal = result.data;
      this.deleteveivocaldialog = true;
    },
    async deleteveivocalSubmit() {
      if (this.$refs.deleteveivocalform.validate()) {
        this.editveivocal.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "veivocal.delete.php",
          this.editveivocal
        );
        if (result.data.status == true) {
          this.veivocal = result.data;
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการลบข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.veivocalQueryAll();
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการลบผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.deleteveivocaldialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
